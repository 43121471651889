<template>
	<div class="down-page">
		<div class="content">
			<div class="section section-download">
				<div class="tab">
					<div
						v-for="(item, index) in tabTitle"
						:key="item.id"
						:class="
							currentCategoty == index ? 'active' : 'noActive'
						"
						@click="chooseCategory(index)"
					>
						<img :src="domain + item.pic" alt="" />
						<h5>{{ item.title }}</h5>
						<p>{{ item.desc }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="sectionOne" v-if="currentCategoty === 0">
			<div class="firsts">
				<div class="first">
					<div class="left">
						<h5>趴趴英语 APP</h5>
						<p>随时随地，想学就学！ 把知识装进口袋。</p>
						<img :src="domain + d_app_qrcode" alt="" />
					</div>
					<div></div>
				</div>
				<img :src="domain + papaen_pic01" alt="" class="bgcImg" />
			</div>
			<div class="seconds">
				<div class="second">
					<img
						:src="domain + 'papaen_pic02.png'"
						alt=""
					/>
					<div class="words">
						<h5>雅思口语素材练习系统</h5>
						<p>完整口语题库，10套个性化地道素材可选</p>
						<p>数十万烤鸭选择的备考神器，专业团队助你智能备考</p>
					</div>
				</div>
			</div>
			<div class="thirds">
				<div class="third">
					<div class="words">
						<h5>趴趴外教口语陪练模考</h5>
						<p>和专业外教1V1视频对话，在真实语境中提升口语能力</p>
						<p>根据学习时间自在约课，考前冲分必练</p>
					</div>
					<img
						:src="
							domain + 'papaen_pic03_new.png'
						"
						alt=""
					/>
				</div>
			</div>
			<div class="forths">
				<div class="forth">
					<img
						:src="domain + 'papaen_pic04.png'"
						alt=""
					/>
					<div class="words">
						<h5>轻松选课，随身学习</h5>
						<p>随时掌握最新活动、课程动向</p>
						<p>视频课支持缓存，省心省时省流量</p>
					</div>
				</div>
			</div>
		</div>
		<div class="sectionTwo" v-if="currentCategoty === 1">
			<div class="firsts">
				<div class="first">
					<div class="left">
						<h5>趴趴雅思 APP</h5>
						<p>全科听说读写备考宝典！</p>
						<img :src="domain + d_ielts_qrcode" alt="" />
					</div>
					<div></div>
				</div>
				<img :src="domain + papaIelts_pic01" alt="" class="bgcImg" />
			</div>
			<div class="seconds">
				<div class="second">
					<img :src="domain + papaIelts_pic02" alt="" />
					<div class="words">
						<h5>口语素材练习系统</h5>
						<p>
							素材基本覆盖当季所有口语考题，且每题配备欧美外教录音供模仿
						</p>
						<p>更多高分参考，提分更简单</p>
					</div>
				</div>
			</div>
			<div class="thirds">
				<div class="third">
					<div class="words">
						<h5>独家写作素材</h5>
						<p>趴趴明星老师独家整理，写作当季高频真题预测</p>
						<p>思路-方法-论点-范文，全篇牵引式指导，不怕学不会</p>
					</div>
					<img :src="domain + papaIelts_pic03_new" alt="" />
				</div>
			</div>
			<div class="forths">
				<div class="forth">
					<img :src="domain + papaIelts_pic04" alt="" />
					<div class="words">
						<h5>专属VIP权益</h5>
						<p>尊享9+项学习特权，海量题库+高分素材+口写训练</p>
						<p>雅思全程备考无忧！</p>
					</div>
				</div>
			</div>
		</div>
		<div class="sectionThree" v-if="currentCategoty === 2">
			<div class="firsts">
				<div class="first">
					<div class="left">
						<h5>趴趴GMAT APP</h5>
						<p>属于自己的GMAT备考中心</p>
						<img :src="domain + d_gmat_qrcode" alt="" />
					</div>
					<div></div>
				</div>
				<img :src="domain + papaGmat_pic01" alt="" class="bgcImg" />
			</div>
			<div class="seconds">
				<div class="second">
					<img :src="domain + papaGmat_pic02" alt="" />
					<div class="words">
						<p>带书出门很麻烦？</p>
						<p>计时做题不方便？</p>
						<h5>海量分类题库在线练！</h5>
					</div>
				</div>
			</div>
			<div class="thirds">
				<div class="third">
					<div class="words">
						<p>换库捉摸不定？</p>
						<p>机经无处找寻？</p>
						<h5>最新考情实时更新！</h5>
					</div>
					<img :src="domain + papaGmat_pic03" alt="" />
				</div>
			</div>
			<div class="forths">
				<div class="forth">
					<img :src="domain + papaGmat_pic04" alt="" />
					<div class="words">
						<p>考试日期倒计时</p>
						<p>自动归纳错题本</p>
						<h5>记录我的GMAT备考轨迹！</h5>
					</div>
				</div>
			</div>
		</div>
		<FloatBar category="ielts"></FloatBar>
    <FloatAds></FloatAds>

	</div>
</template>

<script>
import FloatBar from '@/components/FloatBar/FloatBar.vue';
import FloatAds from '@/components/FloatAds/FloatAds.vue'


export default {
	name: 'DownApp',
	components: {
		FloatBar,
    FloatAds
	},
	created() {
		this.routePath = this.$route.query.id
	},
  computed: {
    domain () {
      console.log(this.$domain)
      return this.$domain + 'image/ow/pc/'
    }
  },
	data() {
		return {
			routePath: 0,
			currentCategoty: 0,
			tabTitle: [
				{
					id: 1,
					title: '趴趴英语 App',
					desc: '语言备考神器',
					pic: 'new_logo_papa.png',
				},
				{
					id: 2,
					title: '趴趴雅思 App',
					desc: '你靠谱的屠鸭伙伴',
					pic: 'new_logo_ielts.png',
				},
				{
					id: 3,
					title: '趴趴GMAT App',
					desc: '你的GMAT备考小助手',
					pic: 'new_logo_gmat.png',
				},
			],
			text: [
				{
					left: {
						title: '雅思口语素材练习系统',
						desc: '完整口语题库，10套个性化地道素材可选<br>数十万烤鸭选择的备考神器，专业团队助你智能备考',
						pic: 'app_pic1.png',
					},
					right: {
						title: '海量分类题库在线练！',
						desc: '带书出门很麻烦？<br>' + '计时做题不方便？',
						pic: 'gmat_pic1.png',
					},
				},
				{
					left: {
						title: '趴趴云阅读',
						desc:
							'每天10分钟，点满英语全技能<br>' +
							'精选外刊新闻，外教朗读纠音，超强团队轮番讲解<br>' +
							'用英语满足你的好奇心\n',
						pic: 'app_pic2.png',
					},
					right: {
						title: '最新考情实时更新！',
						desc: '换库捉摸不定？<br>机经无处找寻？',
						pic: 'gmat_pic2.png',
					},
				},
				{
					left: {
						title: '轻松选课，随身学习',
						desc: ' 随时掌握最新活动、课程动向<br> 视频课支持缓存，省心省时省流量',
						pic: 'app_pic3.png',
					},
					right: {
						title: '记录我的GMAT备考轨迹！',
						desc: '考试日期倒计时<br>' + '自动归纳错题本',
						pic: 'gmat_pic3.png',
					},
				},
			],
			papaen_pic01: 'papaen_pic01.png',
			papaen_pic02: 'papaen_pic02.png',
			papaen_pic03_new:
				this.domain + 'papaen_pic03_new.png',
			papaen_pic04: 'papaen_pic04.png',
			d_app_qrcode: 'new_papa_qrcode.png',
			papaIelts_pic01: 'papaielts_pic01.png',
			papaIelts_pic02: 'papaielts_pic2.png',
			papaIelts_pic03_new:
				this.domain + 'papaielts_pic03_news.png',
			papaIelts_pic04: 'papaielts_pic4.png',
			papaGmat_pic01: 'papagmat_pic01.png',
			papaGmat_pic02: 'papagmat_pic02.png',
			papaGmat_pic03: 'papagmat_pic03.png',
			papaGmat_pic04: 'papagmat_pic04.png',

			d_ielts_qrcode: 'ielts_qrcode.png',
			d_gmat: 'd_gmat1.png',
			d_gmat_qrcode: 'new_gmat_qrcode.png',
		};
	},
	methods: {
		chooseCategory(index) {
			console.log(index);
			this.currentCategoty = index;
			this.$router.push({
				query: {
					id: index
				}
			})
		},
	},
	watch: {
		routePath: {
			handler(newValue) {
				console.log(newValue)
				if(newValue !== undefined) {
					this.currentCategoty = +newValue
				}
			},
			immediate: true
		}
	}
};
</script>

<style lang="less">
.footer {
	margin-top: 0 !important;
}
.sectionOne,
.sectionTwo,
.sectionThree {
	.firsts {
		width: 100%;
		height: 400px;
		position: relative;
		.first {
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 400px;
			text-align: left;
			.left {
				//margin-left: 0.5vw;
				h5 {
					font-size: 30px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					margin: 0;
				}
				p {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #555555;
					margin: 0;
					margin-top: 29px;
				}
				img {
					width: 140px;
					height: 140px;
					margin-top: 29px;
				}
			}
		}
		.bgcImg {
			width: 800px;
			height: 295px;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}

	.seconds,
	.thirds,
	.forths {
		width: 100%;
		height: 400px;
		background: #fafcfd;
	}
	.thirds {
		background-color: #fff;
	}
	.second,
	.third,
	.forth {
		width: 1200px;
		height: 400px;
		background: #fafcfd;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 0 auto;
		.words {
			text-align: left;
			h5 {
				margin: 0;
				font-size: 30px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
			}

			p {
				margin: 0;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #555555;
				line-height: 30px;
			}
			p:nth-of-type(1) {
				margin-top: 29px;
			}
		}
	}
	.third {
		background-color: #fff;
	}
}
.sectionTwo {
	.firsts {
		width: 100%;
		height: 400px;
		position: relative;
		.first {
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 400px;
			text-align: left;
			.left {
				img {
					width: 140px;
					height: 140px;
				}
			}
		}
		.bgcImg {
			width: 800px;
			height: 295px;
			position: absolute;
			bottom: 0;
			right: 80px;
		}
	}
}
.sectionThree {
	.second,
	.third,
	.forth {
		width: 100%;
		height: 400px;
		background: #fafcfd;
		display: flex;
		justify-content: space-around;
		align-items: center;
		.words {
			text-align: left;
			p {
				margin: 0 !important;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #555555;
				line-height: 30px;
			}
			h5 {
				margin: 0;
				font-size: 30px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin-top: 16px;
			}
		}
	}
	.third {
		background-color: #fff;
	}
}
.down-page {
	width: 100%;
	background: #fff;

	& > .content {
		width: 1200px;
		margin: 0 auto;
		margin-top: 30px;
		box-sizing: border-box;
		padding: 20px 30px;
		padding-bottom: 0;
		.section-bg {
			background: #f9fbfd;
		}

		.section-download {
			.tab {
				display: flex;
				justify-content: space-around;
				div {
					background: #fafcfd;
					cursor: pointer;
				}
				.noActive {
					width: 380px;
					height: 308px;
					background: #fafcfd;
					border-radius: 10px;
					opacity: 0.5;
					box-sizing: border-box;
					img {
						width: 128px;
						height: 128px;
						border-radius: 20px;
						margin-top: 48px;
					}
					h5 {
						margin: 0;
						margin-top: 29px;
						font-size: 24px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #304e5d;
					}
					p {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #345c70;
						line-height: 22px;
					}
				}
				.active {
					width: 380px;
					height: 308px;
					background: #fafcfd;
					border-radius: 10px;
					opacity: 1;
					box-sizing: border-box;
					border: 3px solid #29d087;
					img {
						width: 128px;
						height: 128px;
						border-radius: 20px;
						margin-top: 48px;
					}
					h5 {
						margin: 0;
						margin-top: 29px;
						font-size: 24px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #304e5d;
					}
					p {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #345c70;
						line-height: 22px;
					}
				}
				div:nth-of-type(2),
				div:nth-of-type(3) {
					margin-left: 30px;
				}
			}
		}
	}
}
</style>
